import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const connection = import.meta.env.VITE_CONFIG_AZURE_INSIGHT

const appInsights = new ApplicationInsights({
  config: {
    connectionString: connection,
    /* ...Other Configuration Options... */
  },
})

export { appInsights }
