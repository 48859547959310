/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createApp } from 'vue'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import myApp from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'

import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '/node_modules/primeflex/primeflex.css'
import VueKonva from 'vue-konva'
import PrimeVue from 'primevue/config'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import MultiSelect from 'primevue/multiselect'
import Card from 'primevue/card'
import Sidebar from 'primevue/sidebar'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import AutoComplete from 'primevue/autocomplete'
import Avatar from 'primevue/avatar'
import Badge from 'primevue/badge'
import Breadcrumb from 'primevue/breadcrumb'
import Calendar from 'primevue/calendar'
import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple'
import BadgeDirective from 'primevue/badgedirective'
import StyleClass from 'primevue/styleclass'
import DataTable from 'primevue/datatable'
import ConfirmPopup from 'primevue/confirmpopup'
import ColorPicker from 'primevue/colorpicker'
import Column from 'primevue/column'
import Carousel from 'primevue/carousel'
import Checkbox from 'primevue/checkbox'
import Chips from 'primevue/chips'
import ContextMenu from 'primevue/contextmenu'
import Chip from 'primevue/chip'
import Chart from 'primevue/chart'
import ConfirmDialog from 'primevue/confirmdialog'
import TreeTable from 'primevue/treetable'
import SelectButton from 'primevue/selectbutton'
import Dialog from 'primevue/dialog'
import SplitterPanel from 'primevue/splitterpanel'
import Tree from 'primevue/tree'
import InputMask from 'primevue/inputmask'
import Toolbar from 'primevue/toolbar'
import InlineMessage from 'primevue/inlinemessage'
import InputNumber from 'primevue/inputnumber'
import TabView from 'primevue/tabview'
import TieredMenu from 'primevue/tieredmenu'
import Dropdown from 'primevue/dropdown'
import RadioButton from 'primevue/radiobutton'
import InputSwitch from 'primevue/inputswitch'
import Fieldset from 'primevue/fieldset'
import Knob from 'primevue/knob'
import OrganizationChart from 'primevue/organizationchart'
import PickList from 'primevue/picklist'
import ScrollPanel from 'primevue/scrollpanel'
import Steps from 'primevue/steps'
import TriStateCheckbox from 'primevue/tristatecheckbox'
import Message from 'primevue/message'
import Slider from 'primevue/slider'
import Rating from 'primevue/rating'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Textarea from 'primevue/textarea'
import SplitButton from 'primevue/splitbutton'
import Inplace from 'primevue/inplace'
import Skeleton from 'primevue/skeleton'
import Menu from 'primevue/menu'
import Listbox from 'primevue/listbox'
import ProgressBar from 'primevue/progressbar'
import FileUpload from 'primevue/fileupload'
import MegaMenu from 'primevue/megamenu'
import Password from 'primevue/password'
import TabPanel from 'primevue/tabpanel'
import ToggleButton from 'primevue/togglebutton'
import Galleria from 'primevue/galleria'
import Splitter from 'primevue/splitter'
import Menubar from 'primevue/menubar'
import TabMenu from 'primevue/tabmenu'
import ScrollTop from 'primevue/scrolltop'
import Panel from 'primevue/panel'
import PanelMenu from 'primevue/panelmenu'
import Timeline from 'primevue/timeline'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import TreeSelect from 'primevue/treeselect'
import OrderList from 'primevue/orderlist'
import Tag from 'primevue/tag'
import Divider from 'primevue/divider'
import Image from 'primevue/image'
import VueZoomer from 'vue-zoomer'
import { i18n } from './i18n'
import { createPinia } from 'pinia'
import localForage from 'localforage'
import 'leaflet/dist/leaflet.css'

import { JeepSqlite } from 'jeep-sqlite/dist/components/jeep-sqlite'
import { Capacitor } from '@capacitor/core'
import { closeDatabaseAndConnection, initializeDatabase, initializeWebEnvironment } from './db/sqlite'
import { createNewTableIQueuePhoto } from './db/tables'
import { appInsights } from './services/azure.insight.config'
import L from 'leaflet'

//@ts-ignore
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconUrl: 'leaflet/dist/images/marker-icon.png',
})
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => {
      if (registrations.length === 0) {
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js')
          .then((registration) => {
            console.log('Service Worker registrado con éxito:', registration)
          })
          .catch((error) => {
            console.error('Error al registrar el Service Worker:', error)
          })
      } else {
        // console.log('Ya hay un Service Worker registrado.')
      }
    })
    .catch((error) => {
      console.error('Error al obtener los registros de Service Worker:', error)
    })
}

const serializer = {
  serialize: (data: any) => {
    return JSON.stringify(data)
  },
  deserialize: (data: any) => {
    return JSON.parse(data)
  },
}

localForage.config({ name: 'IHS:Production', storeName: 'pinia' })

const pinia = createPinia()
// pinia.use(piniaPluginPersistedstate)

pinia.use((context) => {
  const storeId = context.store.$id

  localForage.getItem(storeId).then((local) => {
    if (local) {
      context.store.$patch(local)
    }
  })

  context.store.$subscribe(
    async (_, state) => {
      await localForage.setItem(storeId, serializer.deserialize(serializer.serialize(state)))
    },
    { detached: true }
  )
})

App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  const url = new URL(event.url)
  const slug = url.href.split(url.origin).join('')
  if (slug) {
    router
      .push({
        path: slug,
      })
      .then(() => {
        return
      })
  }
})
export const app = createApp(myApp)
  // .use(IonicVue)
  .use(pinia)
  .use(router)

  .use(PrimeVue, { ripple: true })
  .use(ToastService)
  .use(VueKonva)
  .use(i18n)
  .use(VueZoomer)
  .directive('tooltip', Tooltip)
  .directive('ripple', Ripple)
  .directive('badge', BadgeDirective)
  .directive('styleclass', StyleClass)
  /* eslint-disable */
  .component('Accordion', Accordion)
  .component('AccordionTab', AccordionTab)
  .component('AutoComplete', AutoComplete)
  .component('Avatar', Avatar)
  .component('Badge', Badge)
  .component('Breadcrumb', Breadcrumb)
  .component('Button', Button)
  .component('Calendar', Calendar)
  .component('Card', Card)
  .component('Carousel', Carousel)
  .component('Chart', Chart)
  .component('Checkbox', Checkbox)
  .component('Chip', Chip)
  .component('Chips', Chips)
  .component('ColorPicker', ColorPicker)
  .component('Column', Column)
  .component('ConfirmDialog', ConfirmDialog)
  .component('ConfirmPopup', ConfirmPopup)
  .component('ContextMenu', ContextMenu)
  .component('DataTable', DataTable)
  .component('DataView', DataView)
  .component('DataViewLayoutOptions', DataViewLayoutOptions)
  .component('Dialog', Dialog)
  .component('Divider', Divider)
  .component('Dropdown', Dropdown)
  .component('Fieldset', Fieldset)
  .component('FileUpload', FileUpload)
  .component('Image', Image)
  .component('InlineMessage', InlineMessage)
  .component('Inplace', Inplace)
  .component('InputMask', InputMask)
  .component('InputNumber', InputNumber)
  .component('InputSwitch', InputSwitch)
  .component('InputText', InputText)
  .component('Galleria', Galleria)
  .component('Knob', Knob)
  .component('Listbox', Listbox)
  .component('MegaMenu', MegaMenu)
  .component('Menu', Menu)
  .component('Menubar', Menubar)
  .component('Message', Message)
  .component('MultiSelect', MultiSelect)
  .component('OrderList', OrderList)
  .component('OrganizationChart', OrganizationChart)
  .component('OverlayPanel', OverlayPanel)
  .component('Paginator', Paginator)
  .component('Panel', Panel)
  .component('PanelMenu', PanelMenu)
  .component('Password', Password)
  .component('PickList', PickList)
  .component('ProgressBar', ProgressBar)
  .component('RadioButton', RadioButton)
  .component('Rating', Rating)
  .component('SelectButton', SelectButton)
  .component('ScrollPanel', ScrollPanel)
  .component('ScrollTop', ScrollTop)
  .component('Slider', Slider)
  .component('Sidebar', Sidebar)
  .component('Skeleton', Skeleton)
  .component('SplitButton', SplitButton)
  .component('Splitter', Splitter)
  .component('SplitterPanel', SplitterPanel)
  .component('Steps', Steps)
  .component('TabMenu', TabMenu)
  .component('TabView', TabView)
  .component('TabPanel', TabPanel)
  .component('Tag', Tag)
  .component('Textarea', Textarea)
  .component('TieredMenu', TieredMenu)
  .component('Timeline', Timeline)
  .component('Toast', Toast)
  .component('Toolbar', Toolbar)
  .component('ToggleButton', ToggleButton)
  .component('Tree', Tree)
  .component('TreeSelect', TreeSelect)
  .component('TreeTable', TreeTable)
  .component('TriStateCheckbox', TriStateCheckbox)

appInsights.loadAppInsights()
appInsights.trackPageView()
Sentry.init({
  app,
  dsn: 'https://f442848078dd4d8dad46c4e1fb9a1f91@o4505488753491968.ingest.sentry.io/4505488755130368',
  enabled: import.meta.env.MODE !== 'development',
  denyUrls: ['api-prod.infinityhomeservices.com'],
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  release: import.meta.env.PACKAGE_VERSION,
  environment: import.meta.env.MODE,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% whi
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample ra
})

if (import.meta.env.VITE_DEVELOPER) {
  Sentry.setTag('developer', import.meta.env.VITE_DEVELOPER)
}

customElements.define('jeep-sqlite', JeepSqlite)

window.addEventListener('DOMContentLoaded', async () => {
  try {
    const platform = Capacitor.getPlatform()
    if (platform === 'web') {
      await initializeWebEnvironment()
    }
    const { db, sqlite } = await initializeDatabase()
    await createNewTableIQueuePhoto(db)
    await closeDatabaseAndConnection(db, sqlite)
    router.isReady().then(async () => {
      app.mount('#app')
    })
  } catch (err) {
    throw new Error(`Error: ${err}`)
  }
})
