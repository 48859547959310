<script lang="ts" setup>
  import ProjectMediaImageComponent from '@/components/ProjectMedia/ProjectMediaImageComponent.vue'
  import { IntialFilter, useProjectStore, useUserStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { onMounted, onUnmounted, ref, toRefs, watch } from 'vue'
  import { Device } from '@capacitor/device'
  import { IQueuePhoto, QueuePhotoType } from '@/db/tables'
  import UploadButtonComponent from '@/components/UploadButtonComponent.vue' // ONIMG,
  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { i18n } from '@/i18n'
  import api from '@/services/api'
  import InfiniteLoading from 'v3-infinite-loading'
  import 'v3-infinite-loading/lib/style.css'
  import GalleryComponent from '@/components/GalleryComponent.vue'
  import ShareImagesBtn from '@/components/ShareImagesBtn.vue'
  import { Capacitor } from '@capacitor/core'
  import { getPermission } from '@/components/PermissionRole/funtions'

  import ProjectMediaVideoComponent from '@/components/ProjectMedia/ProjectMediaVideoComponent.vue'
  import { useSignalRMedia } from '@/composables/useSignalrMedia'
  import { baseUrl } from '@/constants'
  import { IPhotoFeed } from '@/components/PhotoFeedMedia/PhotoFeedMediaImageComponent.vue'
  import { UploadEditoUpdatew } from '@/utils/uploadPhotoBlob'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import FilterMediaComponent from '@/components/FilterMediaComponent.vue'
  const { t } = i18n.global
  const props = defineProps({ projectId: { type: String, required: true } })
  const projectStore = useProjectStore()
  const queueNew = uploadNewQueuePhoto()
  const { queue } = toRefs(queueNew)
  const userStore = useUserStore()
  const { permissions } = storeToRefs(userStore)
  const { fetchProjectMedia, setDefaultProjectsMedia, setProjectMedia, setSocketMedia } = projectStore
  const takeValue = ref(50)
  const skipValue = ref(0)
  const { projectMedia, project, paginationMedia, loadingProjectMedia } = storeToRefs(projectStore)
  const loadingFile = ref(false)
  const isProjectMediaFirstRequestDone = ref(false)
  const filter = ref<IntialFilter>({ uploadType: null, photoType: null, userId: null, startDate: null, endDate: null })
  // const toast = useToast()
  const emit = defineEmits(['uploading'])

  const uploadFiles = async (photos: IQueuePhoto[]) => {
    emit('uploading', photos)
  }

  watch(queue, async (newvalue, oldvalue) => {
    if (newvalue.length === 0 && oldvalue.length > 0) {
      setTimeout(async () => {
        resetPagination()
        setDefaultProjectsMedia()
        await fetchProjectMedia(props.projectId)
      }, 1000)
    }
  })

  const resetPagination = () => {
    takeValue.value = 50
    skipValue.value = 0
    setDefaultProjectsMedia()
  }

  const deleteImage = async (id: string) => {
    await api.delete(`/Api/Project/Photo/${id}`)
    setProjectMedia(projectMedia.value.filter((x) => x.id !== id))
  }

  const updateFavorite = async (value: { id: string; isFavorite: boolean }) => {
    // console.log('value update', value)
    await api.patch(`/Api/Project/Photo/${value.id}/SetFavorite`, { isFavorite: value.isFavorite })
    setProjectMedia(
      projectMedia.value.map((x) => {
        if (x.id === value.id) return { ...x, isFavorite: value.isFavorite }
        return x
      })
    )
  }
  const editImage = async (value: { photoId: string; base_64: string }) => {
    const media = projectMedia.value?.find((e) => e?.id === value?.photoId)
    if (!media) return
    // const base_64_content = value?.base_64?.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    setProjectMedia(
      projectMedia.value.map((x) => {
        if (x.id === value.photoId) {
          const newVariants = x.variants.map((variant) => {
            if (variant.variant === 'ORIGINAL') return variant
            return { ...variant, uri: value.base_64 }
          })
          return { ...x, url: value.base_64, variants: newVariants }
        }
        return x
      })
    )

    const { latitude, longitude } = await fetchCurrentLocationOnce()

    await UploadEditoUpdatew(media.id, {
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      id: props.projectId,
      base64_annotation: value.base_64,
      photoType: media?.type as QueuePhotoType,
      photoTypeId: '',
    })
  }
  const handleScrollPagination = () => {
    if (paginationMedia.value?.nextPage === null) return
    if (loadingProjectMedia?.value) return

    skipValue.value = skipValue.value + 10
    takeValue.value = 10
  }

  watch(
    [skipValue, filter],
    async () => {
      if (paginationMedia.value?.nextPage === null) return
      await fetchProjectMedia(props.projectId, { skip: skipValue.value, take: takeValue.value, media: filter.value })
    },
    { deep: true }
  )
  const isNativePlatform = ref(false)
  const device = ref<'ios' | 'android' | 'web' | 'mac' | 'win'>('web')

  const callback = (data: IPhotoFeed) => {
    return setSocketMedia(data)

    // photoFeedResponse.value.photos = [data, ...(photoFeedResponse.value?.photos ?? [])]
  }

  const { startConnection, stopConnection } = useSignalRMedia(`${baseUrl}/media`, 'media', project?.value?.id, callback)

  onMounted(async () => {
    resetPagination()
    console.log('test')

    startConnection()
    // await chargerQueuePhoto()
    await fetchProjectMedia(props.projectId, { skip: 0, take: takeValue.value })
    isProjectMediaFirstRequestDone.value = true
    if (Capacitor.isNativePlatform()) {
      isNativePlatform.value = Capacitor.isNativePlatform()
      const info = await Device.getInfo()
      device.value = info.platform
    }

    if (!Capacitor.isNativePlatform()) {
      const userAgent = navigator.userAgent.toLowerCase()
      const isWindows = userAgent.includes('win')
      if (isWindows) device.value = 'win'
      const isMac = userAgent.includes('mac')
      if (isMac) device.value = 'mac'
    }
  })

  onUnmounted(() => {
    stopConnection()
  })
  const handleApply = async (values: IntialFilter) => {
    filter.value = values
    setDefaultProjectsMedia()
    resetPagination()
  }
</script>

<template>
  <header class="flex align-items-center justify-content-between title-tab mb-3">
    <FilterMediaComponent type="project" :entity-id="project.id" @apply="handleApply" />
    <PermissionRole v-if="project.isAuthorized" :nodo-name="'projectsMediaPermissions'" :key-name="'capturePhoto'">
      <div class="flex flex-wrap justify-content-end">
        <PermissionRole :nodo-name="'projectsMediaPermissions'" :key-name="'shareImageGallery'">
          <ShareImagesBtn @click="$router.push(`/projects/${project.id}/share-images`)" />
        </PermissionRole>

        <UploadButtonComponent
          entity-type="Project"
          photo-type="PROGRESS_PICTURES"
          :project-id="props.projectId"
          :label-button="t('projectMedia.upload')"
          :icon-button="!loadingFile ? 'pi pi-upload' : 'pi pi-spin pi-spinner'"
          class-button="p-button-text ButtonInputFile"
          @on-media="uploadFiles"
        />
      </div>
    </PermissionRole>
  </header>
  <GalleryComponent
    v-model:images="projectMedia"
    :loading-next-images="loadingProjectMedia"
    show-detail
    :show-add-tag="project.isAuthorized && getPermission(permissions, 'add', 'photoTag')"
    :show-comments="project.isAuthorized"
    :show-delete="project.isAuthorized"
    :show-edit="project.isAuthorized"
    entity-type="Projects"
    :show-favorite="project.isAuthorized"
    @next="handleScrollPagination"
    @delete-image="(id) => deleteImage(id)"
    @edit-image="(data: any | undefined) => editImage(data)"
    @update-favorite="updateFavorite"
  >
    <template #item="slotProps">
      <ProjectMediaVideoComponent v-if="slotProps.item?.mediaType === 'VIDEO'" :is-upload-type-available="true" :photo="slotProps.item" :is-tag-available="true" />
      <ProjectMediaImageComponent v-else :is-upload-type-available="true" :photo="slotProps.item" :is-tag-available="true" />
    </template>
    <template #default>
      <div v-if="!loadingProjectMedia && paginationMedia?.nextPage !== null" class="flex justify-content-center py-3">
        <InfiniteLoading v-show="paginationMedia?.nextPage" @infinite="handleScrollPagination" />
      </div>
    </template>
  </GalleryComponent>
  <div v-if="!projectMedia.length && !loadingProjectMedia && isProjectMediaFirstRequestDone" class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem">
    <h6>{{ t('projectMedia.noMedia') }}</h6>
  </div>
</template>

<style lang="scss">
  #photo-comment .p-sidebar-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  #photo-comment .p-sidebar-header-content {
    width: 100%;
  }
  #photo-comment .p-sidebar-content {
    height: calc(100% - 63px);
    max-height: calc(100% - 63px);
    padding: 0;
    overflow: hidden;
  }
</style>

<style lang="scss" scoped>
  @media (max-width: 494px) {
    .ButtonInputFile {
      font-size: 0px;
    }
  }
  .title-tab {
    min-height: 40px;
  }
  .masonry-item-container {
    height: 200px;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
